<template>
    <a-card>
        <a-page-header
            title='广告计划管理'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row type="flex" justify="start">
                <a-col :span="8">
                    <a-form-model-item label="查询周期">
                        <a-range-picker
                            v-model="pickerTime"
                            value-format="YYYY-MM-DD"
                            :disabled-date="disabledDate"
                            :allow-clear="false"
                            @calendarChange="calendarChange"
                            @openChange="openChange"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告计划">
                        <a-input
                            v-model="form.adPlanName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="8">
                    <a-form-model-item label="任务编码">
                        <a-input
                            v-model="form.missionCode"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="项目编码">
                        <a-input
                            v-model="form.projectCode"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告组ID">
                        <a-input
                            v-model="form.adGroupId"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="8">
                    <a-form-model-item label="系统开关状态">
                        <a-select
                            v-model="form.sysOptStatus"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in switchState" :key="item.id">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告计划状态">
                        <a-select
                            mode="multiple"
                            v-model="form.adStatusList"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in planState" :key="item.code">{{ item.msg }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告计划品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="8">
                    <a-form-model-item label="广告计划车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col span="18">
                    <a-space>
                        <a-button type="primary" @click="batchByAdPlanIds('batchEnableByIds')">批量启用</a-button>
                        <a-button type="primary" @click="batchByAdPlanIds('batchDisableByIds')">批量暂停</a-button>
                        <a-button type="primary" @click="batchByAdPlanIds('batchUpdateBudget')">批量调整预算</a-button>
                        <a-button type="primary" @click="batchByAdPlanIds('batchUpdateBid')">批量调整出价</a-button>
                        <a-button type="primary" @click="batchByAdPlanIds('batchUpdateScheduleTime')">批量调整排期</a-button>
                        <a-button type="primary" @click="batchByAdPlanIds('batchUpdateDistrict')">批量调整地域</a-button>
                    </a-space>
                </a-col>
                <a-col span="6" class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :row-selection="rowSelection"
            :pagination='false'
            :scroll="{x: 2000}"
            bordered
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="'详情'"
                    @onClickBtn="openDetailDrawer(record, 1)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'创意'"
                    @onClickBtn="jumpIdeaPage(record.adPlanId)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'关键词'"
                    @onClickBtn="jumpKeywordPage(record.adPlanId)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'更多操作'"
                    @onClickBtn="openActionModal(record)"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <!-- 抽屉 -->
        <a-drawer
            title="详情"
            placement="right"
            :closable="false"
            :visible="visible"
            :width="1000"
            @close="closeDetailDrawer"
        >
            <a-tabs v-model="activeIndex">
                <a-tab-pane :key="1" tab="详情">
                    <div class="box">
                        <div class="title">广告平台信息</div>
                        <div class="row">
                            <div class="name">广告计划：</div>
                            <div class="detail">{{ dataDetail.adPlanName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划ID：</div>
                            <div class="detail">{{ dataDetail.adPlanId || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告投放位置：</div>
                            <div class="detail">{{ dataDetail.placementArr || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">出价类型：</div>
                            <div class="detail">{{ dataDetail.smartBidTypeDesc || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">竞价策略：</div>
                            <div class="detail">{{ dataDetail.flowControlModeDesc || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划创建时间：</div>
                            <div class="detail">{{ dataDetail.adPlanCreateTime || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划修改时间：</div>
                            <div class="detail">{{ dataDetail.adPlanModifiedTime || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组ID：</div>
                            <div class="detail">{{ dataDetail.adGroupId || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">平台账套ID：</div>
                            <div class="detail">{{ dataDetail.adPlatformAccountId || '-' }}</div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="title">皮皮慧投定义信息</div>

                        <div class="row">
                            <div class="name">系统创建：</div>
                            <div class="detail">{{ dataDetail.createSource == 1 ? '是' : '否' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">系统管控：</div>
                            <div class="detail">{{ dataDetail.isSysControl ? '是' : '否' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划品牌：</div>
                            <div class="detail">{{ dataDetail.principalName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划车系：</div>
                            <div class="detail">{{ dataDetail.carSeriesName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划场景：</div>
                            <div class="detail">{{ dataDetail.customerSceneDesc || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告主：</div>
                            <div class="detail">{{ dataDetail.advertiserName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">当前关联的任务编码：</div>
                            <div class="detail">{{ dataDetail.missionCode || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">当前关联的项目编码：</div>
                            <div class="detail">{{ dataDetail.projectCode || '-' }}</div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="日志">
                    <div style="margin: 20px 0">广告计划：{{ rowData.adPlanName || '-' }}</div>
                    <div>广告计划ID：{{ rowData.adPlanId || '-' }}</div>
                    <a-table
                        :columns="logColumns"
                        :data-source="logsList"
                        :rowKey="(record, index) => index"
                        :pagination='false'
                        bordered
                        style="margin-top: 20px;"
                    ></a-table>
                    <base-pagination
                        :currentPage="paginationLog.current"
                        :pageSize="paginationLog.pageSize"
                        :total="paginationLog.total"
                        @onChange="handlePaginationLogChange"
                        @onShowSizeChange="handlePaginationLogChange"
                    />
                </a-tab-pane>
            </a-tabs>
        </a-drawer>

        <!-- 批量调整预算 -->
        <a-modal
            title="批量调整预算"
            :visible="dataBudget.visible"
            @cancel="handleCancel('batchUpdateBudget')"
        >
            <template slot="footer">
                <a-button type="primary" :disabled="!dataBudget.budget || dataBudget.budget < 300" @click="handleOk('batchUpdateBudget')">
                    提交
                </a-button>
            </template>
            <a-row style="display: flex; align-items: center;">
                <a-col :span="21">
                    <a-input-number v-model="dataBudget.budget" :min="300" :precision="0" class="w-100" />
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="2">元</a-col>
            </a-row>
            <div class="txt">录入金额不能少于300元和不能少于本日消费金额</div>
        </a-modal>

        <!-- 批量调整出价 -->
        <a-modal
            title="批量调整出价"
            :visible="dataBid.visible"
            @cancel="handleCancel('batchUpdateBid')"
        >
            <template slot="footer">
                <a-button type="primary" :disabled="!dataBid.bid || dataBid.bid < 0.1 || dataBid.bid > 10000" @click="handleOk('batchUpdateBid')">
                    提交
                </a-button>
            </template>
            <a-row style="display: flex; align-items: center;">
                <a-col :span="21">
                    <a-input-number v-model="dataBid.bid" :min="0.1" :max="10000" :precision="2" class="w-100" />
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="2">元</a-col>
            </a-row>
            <div class="txt">出价范围0.1~10000</div>
        </a-modal>

        <!-- 批量调整排期 -->
        <a-modal
            title="批量调整排期"
            :visible="dataScheduleTime.visible"
            @ok="handleOk('batchUpdateScheduleTime')"
            @cancel="handleCancel('batchUpdateScheduleTime')"
            width="1200px"
        >
            <template slot="footer">
                <a-button type="primary" :disabled="(dataScheduleTime.curValue.schedule_time).indexOf('1') == -1" @click="handleOk('batchUpdateScheduleTime')">
                    提交
                </a-button>
            </template>
            <MSchedule :curValue="dataScheduleTime.curValue" @receive="scheduleReceive"/>
        </a-modal>

        <!-- 批量调整地域 -->
        <a-modal
            title="批量调整地域"
            :visible="dataDistrict.visible"
            @cancel="handleCancel('batchUpdateDistrict')"
            width="1000px"
        >
            <template slot="footer">
                <a-button type="primary" :disabled="dataDistrict.curValue.district != 'NONE' && dataDistrict.curValue.city == null || dataDistrict.curValue.district == 'REGION' && dataDistrict.curValue.city.length == 0" @click="handleOk('batchUpdateDistrict')">
                    提交
                </a-button>
            </template>
            <DistrictSelection
                :cur-value="dataDistrict.curValue"
                :extensions="dataDistrict.extensions"
                @receive="districtReceive"
            />
        </a-modal>

        <!-- 更多操作  -->
        <a-modal
            :visible="actionVisible"
            :footer="null"
            title="更多操作"
            @cancel="handleActionCancel"
        >
            <base-button
                :type="'link'"
                :title="'操作日志'"
                @onClickBtn="openDetailDrawer(rowData, 2)"
            ></base-button>
            <base-button
                :type="'link'"
                :title="'复制'"
                @onClickBtn="copyData(rowData.id)"
            ></base-button>
            <base-button
                :type="'link'"
                :title="'平台计划托管'"
                @onClickBtn="jumpTrustShip(rowData.id)"
                v-if="rowData.adGroupCreateSource == 1 && rowData.trusteeStatus != 2"
            ></base-button>
            <base-button
                :type="'link'"
                :title="'删除'"
                @onClickBtn="deleteData(rowData.id)"
            ></base-button>
        </a-modal>
    </a-card>
</template>

<script>
import { MSchedule, DistrictSelection } from '@bytedance-ad/mui-vue2'
import moment from 'moment'

const switchState = [
    { id: 1, label: '启用' },
    { id: 2, label: '暂停' },
    { id: 3, label: '删除' },
]

const renderContent = (data, index) => {
    const obj = {
        children: data,
        attrs: {},
    };
    if (index === 0) {
        obj.attrs.colSpan = 0;
    }
    return obj;
}

export default {
    components: {
        MSchedule,
        DistrictSelection,
    },
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                adGroupId: this.$route.query.adGroupId,
                missionCode: this.$route.query.missionCode,
                adStatusList: [],
                principalIdList: this.$route.query.principalId ? [this.$route.query.principalId] : [],
                carSeriesIdList: this.$route.query.carSeriesId ? [this.$route.query.carSeriesId] : [],
            },
            pickerTime: [moment(), moment()],
            endOpen: false,
            loading: false,
            columns: [
                // begin - 展示合计
                // {
                //     align: 'center',
                //     title: '任务系统/巨量开关状态状态',
                //     dataIndex: 'sysOptStatusDesc',
                //     customRender: (text, row, index) => {
                //         if (index > 0) {
                //             return <span>{text || '-'}</span>;
                //         }
                //         return {
                //             children: <span>合计</span>,
                //             attrs: {
                //                 colSpan: 6,
                //             },
                //         }
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '广告计划状态',
                //     dataIndex: 'adStatusDesc',
                //     customRender: (text, row, index) => {
                //         return renderContent(text, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '广告计划广告计划ID',
                //     customRender: (text, row, index) => {
                //         let data = <div>
                //                 <div>{ row.adPlanName || '-' }</div>
                //                 <div class="txt">{row.adPlanId || '-'}</div>
                //             </div>
                //         return renderContent(data, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '操作',
                //     customRender: (text, row, index) => {
                //         let data = <a on-click={() => {this.openDetailDrawer(row.id)}}>详情</a>

                //         return renderContent(data, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '广告计划预算/预算类型',
                //     dataIndex: 'budget',
                //     customRender: (text, row, index) => {
                //         let data = <div>
                //                 <div>{ text || '-' }</div>
                //                 <div class="txt">日预算</div>
                //             </div>

                //         return renderContent(data, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '出价（元）',
                //     dataIndex: 'principalName1',
                //     customRender: (text, row, index) => {
                //         let data = text || '-'

                //         return renderContent(data, index)
                //     }
                // },
                // end - 展示合计

                //begin - 不需要展示合计
                {
                    align: 'center',
                    title: '任务系统/巨量开关状态状态',
                    dataIndex: 'sysOptStatusAndAdOptStatusDesc',
                    customRender: (text, row, index) => {
                        let txt = <div class="red">{text}</div>
                        return row.sysOptStatus == 2 && row.adOptStatus == 'enable' ? txt : text
                        // return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告计划状态',
                    dataIndex: 'adStatusDesc',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告计划广告计划ID',
                    customRender: (text, row, index) => {
                        return <div>
                                <div>{ row.adPlanName || '-' }</div>
                                <div class="txt">{row.adPlanId || '-'}</div>
                            </div>
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'action' }
                },
                {
                    align: 'center',
                    title: '广告计划预算/预算类型',
                    dataIndex: 'budget',
                    customRender: (text, row, index) => {
                        return <div>
                                <div>{ text || '-' }</div>
                                <div class="txt">日预算</div>
                            </div>
                    }
                },
                {
                    align: 'center',
                    title: '出价（元）',
                    dataIndex: 'cpaBid',
                    customRender: (text, row, index) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                //end - 不需要展示合计

                {
                    align: 'center',
                    title: '消费（元）',
                    dataIndex: 'totalCost',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '目标转化数',
                    dataIndex: 'totalConvert',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '转化单价（元）',
                    dataIndex: 'convertCost',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '展示数',
                    dataIndex: 'totalShow',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击数',
                    dataIndex: 'totalClick',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '展示转化率',
                    dataIndex: 'showConvertRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击率',
                    dataIndex: 'clickRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击转化率',
                    dataIndex: 'clickConvertRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '创意数',
                    dataIndex: 'creativeCount',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '本日有消费创意数',
                    dataIndex: 'totalCostCreativeCount',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 50,
                total: 0
            },
            switchState,
            planState: [],
            principalList: [],
            seriesList: [],
            selectedRowKeys: [],
            visible: false,
            activeIndex: 1,
            dataDetail: {},
            actionVisible: false,
            rowData: {},
            logsList: [],
            logColumns: [
                {
                    align: 'center',
                    title: '操作时间',
                    dataIndex: 'createTime',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作内容',
                    dataIndex: 'contentTitle',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作前后内容',
                    width: '40%',
                    customRender: (text, row, index) => {
                        let txt = row.contentLog.map(item => {
                            return <div>{item}</div>
                        })
                        return txt
                    }
                },
                {
                    align: 'center',
                    title: '操作人',
                    dataIndex: 'operator',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作IP',
                    dataIndex: 'optIp',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
            ],
            logsLoading: false,
            paginationLog: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            selectStartDay: '',
            dataBudget: {
                visible: false
            },
            dataBid: {
                visible: false
            },
            dataScheduleTime: {
                visible: false,
                curValue: {
                    schedule_time: ''
                },
            },
            dataDistrict: {
                visible: false,
                curValue: {
                    district: "REGION",
                    city: [],
                },
                extensions: {
                    isV1: true,
                }
            },
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.videoStatus == 3,
                    },
                })
            };
        },
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getAdPlanStatusList()
        this.getDataList()
    },
    methods: {
        disabledDate(current) {
            return current > this.$moment(this.selectStartDay).add(31, 'days') || current < this.$moment(this.selectStartDay).subtract(31, 'days')
        },
        calendarChange(dates, dateStrings) {
            console.log(dates, dateStrings)
            this.selectStartDay = dates[0]
        },
        openChange(status) {
            this.selectStartDay = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res.message}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIdList: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res.message}`)
                }
            })
        },
        getAdPlanStatusList() {
            this.$api.core.JLAdvertManage.getAdPlanStatusList().then((res) => {
                if(res.code == 200) {
                    this.planState = res.data
                    this.form.adStatusList = [this.planState[0].code]
                    this.getDataList()
                } else {
                    this.planState = []
                    this.$message.error(`获取状态列表失败,${res.message}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                adGroupId: this.$route.query.adGroupId,
                missionCode: this.$route.query.missionCode,
                adStatusList: [this.planState[0].code],
                principalIdList: this.$route.query.principalId ? [this.$route.query.principalId] : [],
                carSeriesIdList: this.$route.query.carSeriesId ? [this.$route.query.carSeriesId] : [],
            }
            this.pickerTime = [moment(), moment()]

            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                startTime: `${moment(this.pickerTime[0]).format('YYYY-MM-DD')}`,
                endTime: `${moment(this.pickerTime[1]).format('YYYY-MM-DD')}`,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.JLAdvertManage.getAdPlanList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res.message}`)
                }
            })
        },
        openDetailDrawer(record, type) {
            this.visible = true
            this.activeIndex = type
            this.rowData = record
            this.getAdPlanDetail(record.id)
            this.getActionLogs(record)
            this.actionVisible = false
        },
        closeDetailDrawer() {
            this.visible = false
            this.dataDetail = {}
            this.rowData = {}
        },
        getAdPlanDetail(id) {
            this.$api.core.JLAdvertManage.getAdPlanDetail(id).then((res) => {
                if(res.code == 200) {
                    this.dataDetail = res.data
                } else {
                    this.dataDetail = {}
                    this.$message.error(`获取详情失败,${res.message}`)
                }
            })
        },
        //批量有效、停用
        batchByAdPlanIds(type) {
            if(this.selectedRowKeys.length < 1) {
                this.$message.error('请选择广告计划')
            } else {
                let params = {
                    type
                }
                
                switch (type) {
                    case 'batchEnableByIds':
                        params.data = this.selectedRowKeys
                        this.submitAdPlanIds(params)
                        break;
                    case 'batchDisableByIds':
                        params.data = this.selectedRowKeys
                        this.submitAdPlanIds(params)
                        break;
                    case 'batchUpdateBudget':
                        this.dataBudget.visible = true
                        break;
                    case 'batchUpdateBid':
                        this.dataBid.visible = true
                        break;
                    case 'batchUpdateScheduleTime':
                        this.dataScheduleTime.visible = true
                        break;
                    case 'batchUpdateDistrict':
                        this.dataDistrict.visible = true
                        break;
                }

                
            }
        },
        submitAdPlanIds(params, valid) {
            this.$api.core.JLAdvertManage.batchByAdPlanIds(params).then((res) => {
                this.selectedRowKeys = []
                if(res.code == 200) {
                    this.getDataList()
                    this.$message.success('操作成功')
                    if(valid) {
                        this.handleCancel(params.type)
                    }
                } else {
                    this.$message.error(`操作失败，${res.message}`)
                }
            })
        },
        handleOk(type) {
            let params = {
                type,
                data: {
                    ids: this.selectedRowKeys
                }
            }
            switch (type) {
                case 'batchUpdateBudget':
                    params.data.budget = this.dataBudget.budget
                    break;
                case 'batchUpdateBid':
                    params.data.bid = this.dataBid.bid
                    break;
                case 'batchUpdateScheduleTime':
                    params.data.schedule_time = this.dataScheduleTime.curValue.schedule_time
                    break;
                case 'batchUpdateDistrict':
                    let district = this.dataDistrict.curValue
                    params.data = {
                        ...params.data,
                        ...{
                            district: district.district,
                            city: district.city,
                            location_type: district.location_type,
                        }
                    }
                    break;
            }

            this.submitAdPlanIds(params, true)
        },
        handleCancel(type) {
            switch (type) {
                case 'batchUpdateBudget':
                    this.dataBudget = {
                        visible: false
                    }
                    break;
                case 'batchUpdateBid':
                    this.dataBid = {
                        visible: false
                    }
                    break;
                case 'batchUpdateScheduleTime':
                    this.dataScheduleTime = {
                        visible: false,
                        curValue: {
                            schedule_time: ''
                        }
                    }
                    break;
                case 'batchUpdateDistrict':
                    this.dataDistrict = {
                        visible: false,
                        curValue: {
                            district: "REGION",
                            city: [],
                        },
                        extensions: {
                            isV1: true,
                        }
                    }
                    break;
            }
            this.selectedRowKeys = []
        },
        scheduleReceive(newValue) {
            console.log('scheduleReceive', {newValue})
            this.dataScheduleTime.curValue = newValue
        },
        districtReceive(newValue) {
            console.log('districtReceive', {newValue})
            this.dataDistrict.curValue = newValue
        },
        jumpIdeaPage(adPlanId) {
            this.$router.push({
                path: `/advertisingIdeaManage`,
                query: {
                    adPlanId
                }
            })
        },
        jumpKeywordPage(adPlanId) {
            this.$router.push({
                path: `/advertisingKeywordManage`,
                query: {
                    adPlanId
                }
            })
        },
        openActionModal(record) {
            this.actionVisible = true
            this.rowData = record
        },
        handleActionCancel() {
            this.actionVisible = false
            this.rowData = {}
        },
        getActionLogs(record) {
            this.logsLoading = true
            let params = {
                adPlatformAccountId: record.adPlatformAccountId,
                objectId: [record.adPlanId],
                page: this.paginationLog.current,
                pageSize: this.paginationLog.pageSize
            }
            this.$api.core.JLAdvertManage.getActionLogs(params).then((res) => {
                this.logsLoading = false
                if(res.code == 200) {
                    this.logsList = res.data.list || []
                    this.paginationLog.total = res.data.total || 0
                } else {
                    this.logsList = []
                    this.paginationLog.total = 0
                    this.$message.error(`获取操作日志失败，${res.message}`)
                }
            })
        },
        handlePaginationLogChange(current, pageSize) {
            this.paginationLog.current = current
            this.paginationLog.pageSize = pageSize
            this.getActionLogs(this.rowData)

        },
        copyData(id) {
            let that = this
            this.$confirm({
                title: '确认在广告平台复制当前计划？',
                content: h => <div style="color:red;">PS：新计划不自动绑定任务</div>,
                okText: '是',
                cancelText: '否',
                onOk() {
                    that.$api.core.JLAdvertManage.copyAdPlan(id).then((res) => {
                        if(res.code == 200) {
                            that.getDataList()
                            that.handleActionCancel()
                            that.$message.success('操作成功，请30分钟后再次查看，请勿重复操作')
                        } else {
                            that.$message.error(`操作失败，${res.message}`)
                        }
                    }) 
                },
            });
        },
        deleteData(id) {
            let that = this
            this.$confirm({
                title: '确认删除？',
                okText: '是',
                cancelText: '否',
                onOk() {
                    that.$api.core.JLAdvertManage.deleteAdPlan([id]).then((res) => {
                        if(res.code == 200) {
                            that.getDataList()
                            that.handleActionCancel()
                            that.$message.success('操作成功，请30分钟后再次查看，请勿重复操作')
                        } else {
                            that.$message.error(`操作失败，${res.message}`)
                        }
                    }) 
                },
            });
        },
        jumpTrustShip(id) {
            this.$router.push({
                path: `/advertisingPlanTrustShip`,
                query: {
                    id
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.mt-40 {
    margin-top: 40px;
}
.txt {
    color: #ccc;
}
.box {
    .title {
        margin: 20px 0 10px;
        font-size: 16px;
        color: #999;
    }
    .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .name {
        pad: 5px;
        width: 160px;
        text-align: right;
    }
}
.w-100 {
    width: 100%;
}
</style>